.embed {
  margin-left: auto;
  margin-right: auto;
  max-width: 700px;
  width: 100%;
}

.embed iframe {
  width: 100%;
  margin: auto;
}

.embed img,
.embed video {
  width: 100%;
  margin: 0;
}

.embed blockquote {
  @apply m-0 !important;
  @apply border-0 !important;
  @apply p-0 !important;
}

.embed span {
  border: 0;
}

.embed_column {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.embed_placeholder {
  height: 0;
  padding-bottom: 56.25%;
}

.embedCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.videoEmbedWide {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
}

.videoEmbedWide iframe,
.videoEmbedWide object,
.videoEmbedWide embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
